import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { IconContext } from 'react-icons'

import Header from 'src/components/header'
import Footer from 'src/components/footer'
import './layout.scss'

import logo from 'src/assets/img/logo.svg'
import favicon from 'src/assets/img/favicon.png'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <IconContext.Provider value={{ className: 'icon' }}>
        <>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <link rel="shortcut icon" type="image/png" href={favicon} />
          </Helmet>

          {process.env.NODE_ENV === 'production' && (
            // Helmet doesn't support fragment tags yet
            <Helmet>
              <script>
                {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-M4Q4LS9');
              `}
              </script>
            </Helmet>
          )}
          <noscript>
            <iframe
              title="noscript-gtag"
              src="https://www.googletagmanager.com/ns.html?id=GTM-M4Q4LS9"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
          <Header siteTitle={data.site.siteMetadata.title} logo={logo} />
          <div className="main-content-wrapper">
            <div className="main-content">{children}</div>
          </div>
          <Footer />
        </>
      </IconContext.Provider>
    )}
  />
)

export default Layout
