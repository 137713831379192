import React from 'react'

export default class Collapseable extends React.Component {
  state = { collapsed: true }

  Toggle = ({ name, onToggle, state }) => (
    <input
      type="checkbox"
      id={name}
      className="toggle"
      style={{ display: 'none' }}
      onChange={onToggle}
      checked={!state.collapsed}
    />
  )
  Switch = ({ name, children }) => <label htmlFor={name}>{children}</label>

  toggle() {
    this.setState({ collapsed: !this.state.collapsed })
  }

  collapse() {
    this.setState({ collapsed: true })
  }

  expand() {
    this.setState({ collapsed: false })
  }
}
