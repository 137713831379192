import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { FiPhone, FiZap } from 'react-icons/fi'

import Nav from './nav'

import './header.scss'

const Header = ({ siteTitle, logo }) => (
  <div className="header">
    <Nav top mobile />
    <div className="header-wrapper">
      <Link to="/" className="header-item">
        <img className="logo" src={logo} />
      </Link>

      <div className="header-item cta">
        <Link className="button" to="/contact-us#email">
          <FiZap /> Get a <strong>Free</strong> Quote
        </Link>
        <a className="button" href="tel:(416) 203 7441">
          <FiPhone /> (416) 203 7441
        </a>
      </div>
    </div>
    <Nav top />
  </div>
)

export default Header
