import React from 'react'
import { Link } from 'gatsby'
import { FiMenu } from 'react-icons/fi'
import Collapseable from 'src/components/collapseable'

import './nav.scss'

class Nav extends Collapseable {
  constructor(props) {
    super(props)
    this.classes = []
    if (props.top) this.classes.push('nav-top')
    if (props.mobile) this.classes.push('nav-mobile')
    this.name = 'nav_' + this.classes.join('_')
  }

  item(to, label) {
    return (
      <li>
        <Link to={to} activeClassName="active" onClick={() => this.collapse()}>
          {label}
        </Link>
      </li>
    )
  }

  render() {
    return (
      <nav className={this.classes.join('\n')}>
        <this.Toggle
          name={this.name}
          state={this.state}
          onToggle={() => this.toggle()}
        />
        <ul>
          <li className="nav-toggle" style={{ display: 'none' }}>
            <this.Switch name={this.name}>
              Menu <FiMenu />
            </this.Switch>
          </li>
          {this.item('/', 'About Us')}
          {this.item('/commercial-plumbing', 'Commercial')}
          {this.item('/residential-plumbing', 'Residential')}
          {this.item('/backflow-prevention', 'Backflow')}
          {this.item('/cross-connection-survey', 'Cross Connection Survey')}
          {this.item('/contact-us', 'Contact')}
          {this.props.children}
        </ul>
      </nav>
    )
  }
}

export default Nav
