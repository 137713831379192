import React from 'react'
import { Link } from 'gatsby'

import Nav from './nav'
import Row from 'src/components/row'
import Social from 'src/components/social'

import './footer.scss'

const Footer = () => (
  <div className="footer ribbon ribbon-no-mobile ribbon-full-width">
    <div className="content">
      <div className="right">
        <Social />
      </div>
      <div className="services">
        <h3>Services</h3>
        <Row>
          <ul>
            <li>
              <Link to="/commercial-plumbing">Commercial Plumbing & Drain</Link>
            </li>
            <li>
              <Link to="/residential-plumbing">Residential Plumbing & Drain</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/backflow-prevention">Backflow Prevention</Link>
            </li>
            <li>
              <Link to="/cross-connection-survey">Cross Connection Survey</Link>
            </li>
          </ul>
        </Row>
      </div>
      <Row fill>
        <div className="row-no-pad">
          <Nav />
        </div>
        <div className="copyright">
          © {new Date().getFullYear()} ARC Plumbing
        </div>
      </Row>
    </div>
  </div>
)

export default Footer
